.App {
  margin: 16px;
}

.col1 {
  width: 10%
}

.col2 {
  width: 20%
}

.col3, .col4 {
  width: 30%
}

.col5 {
  width: 10%
}
